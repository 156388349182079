import React from 'react'
import 'twin.macro'

import { BLOCKS } from '@contentful/rich-text-types'
import { Text as ContentfulText } from '@contentful/rich-text-types/dist/types/types'
import { motion } from 'framer-motion'
import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'

import { MxButton as Button } from '../components/section-components'
import { Section } from '../section'

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0, y: 75 },
}

const Hero = ({
  data,
}: {
  data: {
    contentSlots: {
      actionText?: string
      actionUrl?: string
      markdown?: RenderRichTextData<ContentfulRichTextGatsbyReference>
      photos?: {
        localFile?: {
          childImageSharp?: {
            gatsbyImageData?: IGatsbyImageData
          }
        }
      }[]
      slotTitle?: string
      video?: {
        title?: string
        embedUrl?: string
      }
    }[]
  }
}) => {
  const photos = data.contentSlots[0]?.photos
  const video = data.contentSlots[0]?.video

  return (
    <Section>
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <div tw=" auto-rows-min px-4 pt-4 grid grid-cols-6 gap-y-6 md:(grid-cols-16 items-end px-0 pt-0) lg:(pl-16 gap-y-8 auto-rows-auto) xl:(pl-0)">
          <div tw="col-start-1 col-end-7 md:(pl-4 col-start-2 col-end-15) lg:(shrink-0 max-w-3xl) xl:(max-w-none)">
            <h1
              tw="text-4xl font-thin leading-tight"
              dangerouslySetInnerHTML={{
                __html: data.contentSlots[0]?.slotTitle ?? '',
              }}
            />
          </div>

          <div tw="pb-8 pr-4 col-start-1 col-end-7 md:(pb-0 pl-4 col-start-2 col-end-9 pb-0 h-full)">
            {data.contentSlots[0]?.markdown &&
              renderRichText(data.contentSlots[0]?.markdown, {
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => {
                    const { value: textValue } = node
                      .content[0] as ContentfulText

                    if (node.nodeType === 'paragraph' && textValue) {
                      return (
                        <p tw="max-w-sm text-2xl font-light text-gray-600 leading-normal sm:(max-w-md) md:max-w-lg">
                          {children}
                        </p>
                      )
                    }
                  },
                },
                renderText: (text) =>
                  text
                    .split('\n')
                    .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
              })}
            {data.contentSlots[0]?.actionText && (
              <Button
                tw="!my-4 lg:(!mb-0 !my-8)"
                color="default"
                onClick={() => {
                  if (data.contentSlots?.[0]?.actionUrl?.match('^\\/(?!\\/)')) {
                    navigate(data.contentSlots[0]?.actionUrl)
                  } else {
                    window.open(data.contentSlots[0]?.actionUrl, '_blank')
                  }
                }}
              >
                {data.contentSlots[0]?.actionText}
              </Button>
            )}
          </div>
          {(video || photos) && (
            <div tw="pb-8 pr-4 col-start-1 col-end-7 md:(col-start-10 col-end-16 h-full)">
              {video?.embedUrl ? (
                <div tw="mx-2 relative place-self-center overflow-hidden md:(m-auto)">
                  <video
                    autoPlay
                    className="video"
                    loop
                    muted
                    playsInline
                    src={video.embedUrl}
                    title={video.title}
                  />
                </div>
              ) : (
                photos?.[0]?.localFile?.childImageSharp?.gatsbyImageData && (
                  <GatsbyImage
                    alt={data.contentSlots[0]?.slotTitle ?? ''}
                    tw="md:(h-full)"
                    image={photos[0]?.localFile.childImageSharp.gatsbyImageData}
                  />
                )
              )}
            </div>
          )}
        </div>
      </motion.div>
    </Section>
  )
}

export { Hero }
